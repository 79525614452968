import { EmailPasswordCredential } from 'types'
import firebaseConfig from 'configs/firebase-config.json'

export function validateFormSignInEmailPassword(
  input: EmailPasswordCredential
) {
  let error: any = {}
  if (!input.email) {
    error.email = 'Email không được bỏ trống!'
  }
  if (!input.password) {
    error.password = 'Mật khẩu không được bỏ trống!'
  }
  return error
}

export function isEmail(email: string) {
  return /\S+@\S+\.\S+/.test(email)
}

export function validateFormAddNewStudent({
  email,
  password,
  displayName
}: {
  email: string
  password: string
  displayName: string
}): { [key: string]: string } {
  let errors: any = {}

  if (!email.trim()) {
    errors.email = 'Email không được bỏ trống!'
  } else if (!isEmail(email)) {
    errors.email = 'Email không đúng định dạng!'
  }

  if (!password.trim()) {
    errors.password = 'Mật khẩu không được bỏ trống!'
  } else if (password.length < 8) {
    errors.password = 'Mật khẩu phải có ít nhất 8 ký tự!'
  } else if (/\s/.test(password)) {
    errors.password = 'Mật khẩu không được chứa khoảng trắng!'
  }

  if (!displayName.trim()) {
    errors.displayName = 'Tên hiển thị không được bỏ trống!'
  }

  return errors
}

export const getCustomToken = () => {
  return fetch(firebaseConfig.cloudfunctionURL + 'callable-verifyLogin', {
    method: 'POST',
    credentials: 'include'
  }).then(async (response) => {
    if (response.ok) {
      return response.json()
    } else {
      throw new Error(response.statusText)
    }
  })
}

export const postIdTokenToFunctionUserLogin = (idToken: string) => {
  return fetch(firebaseConfig.cloudfunctionURL + 'callable-userLogin', {
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify({ idToken: idToken })
  }).then((resposnse) => {
    if (resposnse.ok) {
      return true
    } else {
      throw new Error(resposnse.statusText)
    }
  })
}

export const clearSessionToken = () => {
  return fetch(firebaseConfig.cloudfunctionURL + 'callable-userLogout', {
    method: 'POST',
    credentials: 'include'
  }).then((response) => {
    if (response.ok) {
      return true
    } else {
      throw new Error(response.statusText)
    }
  })
}

export function validateRegisterAccountWithEmailAndPassword(
  displayName: string,
  email: string,
  password: string,
  confirmPassword: string
) {
  const errs: {
    displayName?: string
    email?: string
    password?: string
    confirmPassword?: string
  } = {}

  if (!displayName) {
    errs.displayName = 'Không được bỏ trống tên hiển thị!'
  }

  if (!email) {
    errs.email = 'Không được bỏ trống email!'
  } else if (!isEmail(email)) {
    errs.email = 'Email không đúng định dạng!'
  }

  if (!password) {
    errs.password = 'Không được bỏ trống mật khẩu!'
  } else if (password.length < 8) {
    errs.password = 'Mật khẩu phải có ít nhất 8 ký tự!'
  }

  if (!confirmPassword) {
    errs.confirmPassword = 'Không được bỏ trống xác nhận mật khẩu!'
  } else if (confirmPassword !== password) {
    errs.confirmPassword = 'Mật khẩu không khớp!'
  }

  return errs
}

export function validateInfomationUser(info: { displayName: string }) {
  const errors: { [key: string]: string } = {}

  if (!info.displayName) {
    errors.displayName = 'Tên không thể bỏ trống!'
  }

  return errors
}
